<template>
  <div class="promotionRecord">
    <!--上传-->
    <div class="headerMsgBox">
      <div class="first">
        <p>活动名称：{{ headerInfo.name }}</p>
        <p>
          活动时间：{{
            headerInfo.start_time | formatTimeStamp('yyyy-MM-dd hh:mm')
          }}
          <span>
            - {{ headerInfo.end_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </span>
        </p>
        <p>
          创建时间：{{
            headerInfo.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
          }}
        </p>
      </div>
      <div class="second">
        <p>分成比：{{ headerInfo.commission_ratio }}%</p>
        <p>累计订单：{{ headerInfo.order_num }}</p>
        <p>创建人：{{ headerInfo.uname }}</p>
      </div>
      <div class="third">
        <p>
          参与条件：{{ headerInfo.join_condition == 1 ? '无需购买' : '需购买' }}
        </p>
        <p>累计收益：￥{{ headerInfo.courseprice }}</p>
      </div>
      <div class="fouth">
        <p>
          状态：
          <span :class="{ redYJS: headerInfo.status == 3 }">
            {{
              headerInfo.status == 1
                ? '未开始'
                : headerInfo.status == 2
                ? '进行中'
                : '已结束'
            }}
          </span>
        </p>
        <p>累计佣金：￥{{ headerInfo.brokerage_amount }}</p>
      </div>
    </div>

    <div class="promotionHeader">
      <div class="input">
        <el-input
          placeholder="输入学员名称/备注搜索"
          v-model="postRecord.search"
          class="input-with-select"
        >
          <el-select
            v-model="postRecord.type"
            slot="prepend"
            placeholder="推广者"
          >
            <el-option label="推广者" value="1"></el-option>
            <el-option label="购买者" value="2"></el-option>
          </el-select>
        </el-input>
      </div>
    </div>
    <div class="contentVideo">
      <!-- 左侧菜单 -->
      <div class="rightMenu">
        <!-- 右侧内容视频 列表- 分页 -->
        <div class="rightCon">
          <pagination2
            :option="postRecord"
            url="/Fission/record"
            ref="childBackRecord"
            @complete="completeRecord"
          >
            <template v-slot:default="{ tableData }">
              <el-table
                :data="tableData.list"
                @sort-change="sortChange"
                max-height:200
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column prop="file_name" label="课程" min-width="245">
                  <template slot-scope="scope">
                    <div class="TitleVideo">
                      <div class="imgVideo">
                        <img
                          :src="
                            scope.row.photo
                              ? scope.row.photo
                              : require('../../assets/img/1.3.9.6/img_mrpt@2x.png')
                          "
                          alt=""
                        />
                      </div>
                      <div class="textVideo">
                        <!-- 名字------------------------ -->
                        <p :title="scope.row.name">{{ scope.row.name }}</p>
                        <h5>{{ scope.row.price }}</h5>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="推广者" min-width="80px">
                  <template slot-scope="scope">
                    <div class="promoterBox">
                      <img :src="scope.row.brokerage_photo" alt="" />
                      <p
                        :title="
                          scope.row.brokerage_name +
                            (scope.row.brokerage_remarks
                              ? '(' + scope.row.brokerage_remarks + ')'
                              : '')
                        "
                      >
                        {{
                          scope.row.brokerage_name +
                            (scope.row.brokerage_remarks
                              ? '(' + scope.row.brokerage_remarks + ')'
                              : '')
                        }}
                      </p>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="购买者" min-width="80px">
                  <template slot-scope="scope">
                    <div class="buyerBox">
                      <img :src="scope.row.uphoto" alt="" />
                      <p
                        :title="
                          scope.row.uname +
                            (scope.row.student_remarks
                              ? '(' + scope.row.student_remarks + ')'
                              : '')
                        "
                      >
                        {{
                          scope.row.uname +
                            (scope.row.student_remarks
                              ? '(' + scope.row.student_remarks + ')'
                              : '')
                        }}
                      </p>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="buyPrice"
                  label="成交价"
                  min-width="55px"
                  sortable="custom"
                >
                  <template slot-scope="scope">
                    <p>{{ scope.row.courseprice }}</p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="promoterPrice"
                  label="佣金"
                  min-width="55px"
                  sortable="custom"
                >
                  <template slot-scope="scope">
                    <p>{{ scope.row.brokerage }}</p>
                  </template>
                </el-table-column>

                <el-table-column prop="bfb" label="成交分成" min-width="55px">
                  <template slot-scope="scope">
                    <p style="text-align: left;">
                      {{
                        scope.row.commission_ratio == 0
                          ? '-'
                          : scope.row.commission_ratio + '%'
                      }}
                    </p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="buyTime"
                  label="成交时间"
                  sortable="custom"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.orderTime | formatTimeStamp('yyyy-MM-dd hh:mm')
                    }}
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" min-width="95px">
                  <template slot-scope="scope">
                    <div class="btns">
                      <p @click="seeOrder(scope.row)">查看订单</p>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
          <!--分页-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataBase from '@/mixin/dataBase'

export default {
  name: 'promotionRecord',

  mixins: [dataBase],

  components: {},

  data() {
    return {
      tableData: [],
      headerInfo: {},
      postRecord: {
        fission_id: '',
        type: '1',
        search: '',
        order: '',
        sort: '',
      },
    }
  },
  beforeMount() {
    // console.log(this.$route.params.id)
    this.postRecord.fission_id = this.$route.params.id
  },

  methods: {
    getData() {},
    seeOrder(row) {
      if (row.type == 1) {
        this.$router.push({
          path: `/capitalOrder/detail?data_id=${row.order_id}`,
        })
      } else {
        this.$router.push({
          path: '/capitalOrder/graDetails',
          query: { id: row.order_id },
        })
      }
    },
    completeRecord(val) {
      // console.log(val.data)
      this.headerInfo = val.data.find
    },
    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      switch (value.prop) {
        case null:
          break
        case 'buyPrice':
          order = 1
          break
        case 'promoterPrice':
          order = 2
          break
        case 'buyTime':
          order = 3
          break
      }
      this.postRecord.order = order
      this.postRecord.sort = sort
    },
  },
}
</script>
<style lang="scss" scoped>
.redYJS {
  color: red;
}
.headerMsgBox {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  border-bottom: 20px solid rgba(245, 245, 245);

  p {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 12px;
    margin: 20px 0;
  }
}

.ppp {
  width: 90px;
  text-align: left;
}

.zJName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

.el-dropdown-menu__item {
  width: 80px;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 12px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.promoterBox {
  display: flex;
  justify-content: flex-start;
  height: 100%;

  img {
    width: 26px;
    height: 26px;
    border-radius: 4px;
  }

  p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin-top: 7px;
    margin-left: 10px;
  }
}

.buyerBox {
  display: flex;
  justify-content: flex-start;
  height: 100%;

  img {
    width: 26px;
    height: 26px;
    border-radius: 4px;
  }

  p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin-top: 7px;
    margin-left: 10px;
  }
}

.TitleVideo {
  display: flex;

  .imgVideo {
    width: 78px;
    height: 38px;
    position: relative;
    overflow: hidden;

    img {
      width: 68px;
      height: 38px;
    }
  }

  .textVideo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;

    p {
      min-width: 68px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h5 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ff3535;
      line-height: 14px;
      font-weight: normal;
    }
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 20px;

  .fff {
    width: 250px;
    height: 45px;
    visibility: hidden;
  }

  .leftMenu {
    width: 250px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    position: relative;
    min-height: 400px;
    height: calc(100% - 45px);

    .boxMenu {
      margin-top: 36px;
      width: 250px;

      .childMenu {
        width: 250px;
        height: 36px;
        // background: #F0F8FF;
        cursor: pointer;

        img {
          width: 15px;
          height: 14px;
          position: relative;
          top: 12px;
          left: 20px;
        }

        p {
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          // color: #0AA29B;
          position: relative;
          left: 40px;
          bottom: 1px;
        }

        .hoverDDD {
          display: none;
          width: 25px;
          height: 26px;
          margin-left: 210px;
          margin-top: -16px;
          position: relative;
        }

        .childchildMenu {
          position: absolute;
          z-index: 999;
          width: 100px;
          height: 88px;
          right: 0;
          box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          border: 1px solid #f7f7f7;
          display: none;

          div:hover {
            background-color: #f0f8ff;
          }

          div {
            width: 100px;
            height: 44px;
            background: #fff;
            text-align: center;

            span {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #4a4a4a;
              line-height: 44px;
            }
          }
        }
      }
    }

    .footAddMenu {
      position: absolute;
      top: 0;
      width: 250px;
      height: 36px;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 11px;
        left: 82px;
      }

      p {
        height: 13px;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 36px;
      }
    }
  }

  .rightMenu {
    flex: 1;
    position: relative;
    overflow-x: hidden;
    padding-right: 20px;
  }
}

.promotionRecord {
  background: #fff;
}

::v-deep .promotionRecord .el-table {
  padding: 0 20px;
}

.promotionHeader {
  display: flex;
  margin-bottom: 20px;
  min-width: 850px;
  margin-top: 20px;
  padding: 0 20px 0 20px;
  flex-wrap: wrap;

  .el-input {
    width: 300px;
  }

  ::v-deep .input {
    margin-left: 20px;

    .el-input-group__prepend {
      width: 55px;
    }
  }
}

.btns {
  @extend %btns;
  position: relative;

  p {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #1b9d97;
    line-height: 14px;
    cursor: pointer;
  }
}
</style>
